<template>
  <b-row>
    <validation-observer ref="firstForm">
      <b-form class="d-flex">
        <b-row>
          <b-col lg="8" md="12" sm="12" cols="12">
            <h4 class="text-warning font-weight-bolder mb-1">Cari Bilgileri</h4>
            <b-row class="mx-50">
              <b-col class="p-25" lg="4" md="12" sm="12" cols="12">
                <b-form-group label="Adı*" label-for="basicInput">
                  <validation-provider
                      #default="{ errors }"
                      name="adi"
                      rules="required"
                  >
                    <b-input-group>
                      <b-input-group-prepend is-text :class="errors.length > 0 ? 'input-error':''">
                        <font-awesome-icon icon="user"/>
                      </b-input-group-prepend>
                      <b-form-input
                          v-model="newCariData.adi"
                          :state="errors.length > 0 ? false:null"
                          autocomplete="off"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] ? 'Ad zorunludur' : '' }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col class="p-25" lg="4" md="12" sm="12" cols="12">
                <b-form-group label="Soyadı*" label-for="basicInput">
                  <validation-provider
                      #default="{ errors }"
                      name="Soyadi"
                      rules="required"
                  >
                    <b-input-group>
                      <b-input-group-prepend is-text :class="errors.length > 0 ? 'input-error':''">
                        <font-awesome-icon icon="user"/>
                      </b-input-group-prepend>
                      <b-form-input
                          v-model="newCariData.soyAdi"
                          :state="errors.length > 0 ? false:null"
                          autocomplete="off"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] ? 'Soyadı zorunludur' : '' }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col class="p-25" lg="4" md="12" sm="12" cols="12">
                <b-form-group
                    label="Adres"
                    label-for="basicInput"
                >
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      <font-awesome-icon icon="location-dot"/>
                    </b-input-group-prepend>
                    <b-form-input
                        id="basicInput"
                        v-model="newCariData.adres"
                        autocomplete="off"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col class="p-25" lg="4" md="12" sm="12" cols="12">
                <b-form-group
                    label="E-Posta"
                    label-for="basicInput"
                >
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      <font-awesome-icon icon="envelope"/>
                    </b-input-group-prepend>
                    <b-form-input
                        id="basicInput"
                        v-model="newCariData.ePosta"
                        autocomplete="off"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col class="p-25" lg="4" md="12" sm="12" cols="12">
                <b-form-group
                    label="GSM"
                    label-for="basicInput"
                >
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      <font-awesome-icon icon="hashtag"/>
                    </b-input-group-prepend>
                    <b-form-input
                        id="basicInput"
                        v-model="newCariData.gsm"
                        autocomplete="off"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col class="p-25" lg="4" md="12" sm="12" cols="12">
                <b-form-group
                    label="Telefon"
                    label-for="basicInput"
                >
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      <font-awesome-icon icon="phone"/>
                    </b-input-group-prepend>
                    <b-form-input
                        id="basicInput"
                        v-model="newCariData.telefon"
                        autocomplete="off"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="4" md="12" sm="12" cols="12">
            <h4 class="text-success font-weight-bolder mb-1">Fatura Bilgileri</h4>
            <b-row class="mx-50">
              <b-col cols="12">
                <b-form-group
                    label="Firma Unvanı"
                    label-for="basicInput"
                >
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      <font-awesome-icon icon="paperclip"/>
                    </b-input-group-prepend>
                    <b-form-input
                        id="basicInput"
                        v-model="newCariData.faturaUnvan"
                        autocomplete="off"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="Vergi No / Tc No*">
                  <validation-provider
                      #default="{ errors }"
                      name="TcNo"
                      rules="required|min:10"
                  >
                    <b-input-group>
                      <b-input-group-prepend is-text :class="errors.length > 0 ? 'input-error':''">
                        <font-awesome-icon icon="user"/>
                      </b-input-group-prepend>
                      <b-form-input
                          v-model="newCariData.vergiNumarasi"
                          :state="errors.length > 0 ? false:null"
                          autocomplete="off"
                          type="number"
                      />
                    </b-input-group>
                    <small v-if="tcControl" class="text-danger">
                      {{ errors[0] ? 'Vergi No veya Tc No 10 ila 11 karakter arasında olmalıdır.' : '' }}
                    </small>
                    <small v-if="!tcControl" class="text-danger">
                      Vergi No veya Tc No 10 ila 11 karakter arasında olmalıdır.
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                    label="Vergi Dairesi"
                    label-for="basicInput"
                >
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      <font-awesome-icon icon="building"/>
                    </b-input-group-prepend>
                    <b-form-input
                        id="basicInput"
                        v-model="newCariData.vergiDairesi"
                        autocomplete="off"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <hr>
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="mr-1 mt-50"
                    variant="relief-success"
                    type="submit"
                    @click.prevent="validationForm()"
                >
                  <font-awesome-icon icon="floppy-disk" class="mr-50"/>
                  Güncelle
                </b-button>
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="relief-warning"
                    class="mt-50"
                    @click="$emit('updating', 1)"
                >
                  <font-awesome-icon icon="chevron-left" class="mr-50"/>
                  Vazgeç
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-row>
</template>

<script>
import {
  BForm,
  BInputGroupAppend,
  BFormFile,
  VBModal,
  BDropdown,
  BDropdownItem,
  BCard,
  BCardBody,
  BCardHeader,
  BListGroup,
  BListGroupItem,
  BTabs,
  BTab,
  BButton,
  BRow,
  BCol,
  BModal,
  BFormGroup,
  BFormInput,
  BCardText,
  BFormCheckbox,
  BFormRadio,
  BSpinner,
  VBTooltip,
  BBadge,
  BInputGroupPrepend, BInputGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ModelListSelect } from 'vue-search-select/dist/VueSearchSelect.common'
import 'vue-search-select/dist/VueSearchSelect.css'
import store from '@/store'
import axios from 'axios'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BForm,
    BInputGroupAppend,
    BInputGroupPrepend,
    BInputGroup,
    BFormFile,
    ModelListSelect,
    BDropdown,
    BDropdownItem,
    BCard,
    BCardBody,
    BCardHeader,
    BListGroup,
    BListGroupItem,
    BBadge,
    BSpinner,
    ToastificationContent,
    BFormRadio,
    BFormCheckbox,
    BCardText,
    BFormGroup,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BModal,
    BCardActions,
    BRow,
    BCol,
    BButton,
    BTabs,
    BTab,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: ['cariData'],
  data() {
    return {
      newCariData: [],
      oldCariData: [],
      tcControl: true,
    }
  },
  computed: {},
  watch: {
    vergiNumarasi(newVal) {
      if (newVal?.length <= 11) {
        this.tcControl = true
      } else {
        this.tcControl = false
      }
    },
  },
  created() {
    this.newCariData = { ...this.cariData }
    this.oldCariData = { ...this.cariData }
  },
  methods: {
    cariEdit() {
      var guncellenenKeyler = {}
      Object.entries(this.oldCariData)
          .forEach(entry => {
            const [key, value] = entry
            if (this.newCariData[key] !== undefined && this.newCariData[key] !== value) {
              guncellenenKeyler[key] = this.newCariData[key]
            }
          })
      const fd = this.postSchema()
      fd.append('methodName', 'cariDuzenle')
      fd.append('cariKartID', this.cariData.cariKartID)
      Object.entries(guncellenenKeyler)
          .forEach(item => {
            const [key, value] = item
            fd.append(`updateList[${key}]`, value || ' ')
          })
      let keys = Array.from(fd.keys())
      let hasUpdateList = keys.some(key => key.includes('updateList'))
      if (hasUpdateList) {
        axios.post('', fd)
            .then(() => this.$emit('updating', 1))
      } else {
        this.$emit('updating', 1)
        this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Değişiklik Yapılmadı',
                icon: 'InfoIcon',
                variant: 'secondary',
              },
            },
        )
      }
    },
    validationForm() {
      this.$refs.firstForm.validate()
          .then(success => {
            if (success) {
              if (this.newCariData.vergiNumarasi?.length <= 11) {
                this.tcControl = true
                this.cariEdit()
              } else {
                this.tcControl = false
              }
            }
          })
    },
    postSchema() {
      const fd = new FormData()
      fd.append('crm_token', store.state.userToken || localStorage.getItem('restoranUserToken'))
      fd.append('serviceName', 'cari')
      return fd
    },
    refreshStop(cardName) {
      setTimeout(() => {
        this.$refs[cardName || 'first-card'].showLoading = false
      }, 1000)
    },
  },
}
</script>
<style>
</style>
