<template>
  <b-row>
    <b-col lg="12" md="12" sm="12" cols="12">
      <b-card-actions
          ref="first-card"
          class="list-card"
          @refresh="refreshStop('first-card')"
      >
        <b-row class="mt-50 mb-5">
          <div class="card-header-row" :style="{backgroundColor: headerManagement.backgroundColor}">
            <b-col cols="12" class="d-flex justify-content-between">
              <h4 class="text-white d-inline-block card-title">{{ headerManagement.infoText }}</h4>
              <div>
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="info"
                    class="row-btn mr-50"
                    @click="print()"
                >
                  <font-awesome-icon icon="print" class="mr-50"/>
                  Yazdır
                </b-button>
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="warning"
                    class="row-btn"
                    @click="$router.go(-1)"
                >
                  <font-awesome-icon icon="angle-left" class="mr-50"/>
                  Geri Dön
                </b-button>
              </div>
            </b-col>
          </div>
        </b-row>
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="info"
            class="mobile-row-btn mr-50"
            @click="print()"
        >
          <font-awesome-icon icon="print" class="mr-50"/>
          Yazdır
        </b-button>
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="warning"
            class="mobile-row-btn"
            @click="$router.go(-1)"
        >
          <font-awesome-icon icon="angle-left" class="mr-50"/>
          Geri Dön
        </b-button>
        <b-row class="mt-1">
          <b-col cols="12">
            <h4>Bakiye:
              {{
                Number(bakiye)
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, '$&,')
              }}
              <font-awesome-icon icon="turkish-lira-sign"/>
            </h4>
          </b-col>
        </b-row>
        <hr>
        <div>
          <div
              v-for="item in cariEkstre"
              :key="item.kasaID"
              class="transaction-item"
          >
            <b-media no-body>
              <b-media-aside>
                <b-avatar
                    v-b-tooltip="item.hareket ==='1'? 'Tahsilat':'Borç Girişi'"
                    rounded
                    size="42"
                    :variant="item.hareket === '1' ? 'light-success':'light-danger'"
                    class="pull-up"
                >
                  <font-awesome-icon icon="turkish-lira-sign" class="fa-xl"/>
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h6 class="transaction-title font-weight-bolder m-0">
                  {{
                    Number(item.tutar)
                        .toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                  }}
                  <font-awesome-icon icon="turkish-lira-sign"/>
                </h6>
                <b-badge
                    pill
                    variant="light-primary"
                    class="giris-turu-badge"
                >
                  {{ $store.getters.GET_EKSTRE_GIRIS_TURU(item.girisTuru) }}
                </b-badge>
                <hr class="m-0 mt-50 islem-hr-color" :style="{borderColor : islemHrColor(item.hareket)}">
                <p class="d-inline-block m-0">Açıklama : </p><strong class="d-inline-block">{{ item.aciklama }}</strong>
                <br>
                <!--                <small class="d-inline-block m-0">İşlem Türü : </small><small class="d-inline-block">{{-->
                <!--                  item.hareket === '1' ? 'Tahsilat' : 'Giriş Türü'-->
                <!--                }}</small>-->
              </b-media-body>
              <div
                  class="font-weight-bolder text-right"
                  :class="item.hareket === 1 ? 'text-success':'text-danger'"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-danger"
                    class="pull-up"
                    size="sm"
                    @click="islemRemove(item.kasaID)"
                >
                  <font-awesome-icon icon="trash" class="mr-50"/>
                  Kaydı Sil
                </b-button>
                <small class="d-block mt-50">
                  <b-badge
                      pill
                      variant="light-secondary"
                  >{{ item.kayitTarih }}
                  </b-badge>
                </small>
              </div>
            </b-media>
            <hr>
          </div>
        </div>
        <div v-if="yazdir" ref="cari-ekstre">
          <table v-for="item in cariEkstre" :key="item.kasaID"
                 class="table table-striped ekstre-list-table"
          >
            <thead>
            <tr>
              <th width="20%" :style="{backgroundColor: $store.state.colors.tableHeaderColor || '#3c6e71'}"
                  scope="col"
              >İşlem Türü
              </th>
              <th width="20%" :style="{backgroundColor: $store.state.colors.tableHeaderColor || '#3c6e71'}"
                  scope="col"
              >Açıklama
              </th>
              <th width="20%" :style="{backgroundColor: $store.state.colors.tableHeaderColor || '#3c6e71'}"
                  scope="col"
              >İşlem Tarihi
              </th>
              <th width="20%" :style="{backgroundColor: $store.state.colors.tableHeaderColor || '#3c6e71'}"
                  scope="col"
              >Tutar
              </th>
              <th width="20%" :style="{backgroundColor: $store.state.colors.tableHeaderColor || '#3c6e71'}"
                  scope="col"
              >Bakiye
              </th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>
                <p class="m-0 font-weight-bolder">
                  {{
                    $store.getters.GET_EKSTRE_GIRIS_TURU(item.girisTuru)
                  }}</p>
              </td>
              <td>
                <p class="m-0 font-weight-bolder">{{ item.aciklama }}</p>
              </td>
              <td>
                <p class="m-0">{{ item.kayitTarih.slice(0, 16) }}</p>
              </td>
              <td>
                <p class="m-0 font-weight-bolder">{{ item.tutar }} TL</p>
              </td>
              <td>
                <p class="m-0 font-weight-bolder">{{ item.bakiye }} TL</p>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </b-card-actions>
    </b-col>
  </b-row>
</template>

<script>
import {
  BAvatar,
  BForm,
  BInputGroupAppend,
  BFormFile,
  VBModal,
  BDropdown,
  BDropdownItem,
  BCard,
  BCardBody,
  BCardHeader,
  BListGroup,
  BListGroupItem,
  BTabs,
  BTab,
  BButton,
  BRow,
  BCol,
  BModal,
  BFormGroup,
  BFormInput,
  BCardText,
  BFormCheckbox,
  BFormRadio,
  BSpinner,
  VBTooltip,
  BBadge,
  BInputGroupPrepend, BInputGroup, BMediaAside, BMedia, BMediaBody,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ModelListSelect } from 'vue-search-select/dist/VueSearchSelect.common'
import 'vue-search-select/dist/VueSearchSelect.css'
import store from '@/store'
import axios from 'axios'
import CariUpdate from '@/views/cari/cariUpdate.vue'

export default {
  components: {
    BMediaBody,
    BMedia,
    BMediaAside,
    CariUpdate,
    BAvatar,
    BForm,
    BInputGroupAppend,
    BInputGroupPrepend,
    BInputGroup,
    BFormFile,
    ModelListSelect,
    BDropdown,
    BDropdownItem,
    BCard,
    BCardBody,
    BCardHeader,
    BListGroup,
    BListGroupItem,
    BBadge,
    BSpinner,
    ToastificationContent,
    BFormRadio,
    BFormCheckbox,
    BCardText,
    BFormGroup,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BModal,
    BCardActions,
    BRow,
    BCol,
    BButton,
    BTabs,
    BTab,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      yazdir: false,
      bakiye: 0,
      id: this.$route.params.id,
    }
  },
  computed: {
    cariEkstre: () => store.getters.GET_CARI_EKSTRE,
    headerManagement() {
      return {
        backgroundColor: store.state.colors.headerRowColor,
        infoText: 'Tahsilat ve Borç Kayıtları',
      }
    },
  },
  watch: {},
  created() {
    this.getCariEkstre()
  },
  beforeDestroy() {
    store.commit({
      type: 'SET_CARI_EKSTRE',
      action: 0,
    })
  },
  methods: {
    print() {
      this.yazdir = true
      this.$nextTick(() => store.dispatch('tablePrint', this.$refs['cari-ekstre'].innerHTML))
    },
    islemHrColor: hareket => {
      switch (hareket) {
        case '1':
          return '#64CCC5'
        case '2':
          return '#E25E3E'
        default:
          return '#793FDF'
      }
    },
    islemRemove(kasaID) {
      const fd = this.postSchema()
      fd.append('methodName', 'kasaSil')
      fd.append('cariKartID', this.id)
      fd.append('kasaID', kasaID)
      axios.post('', fd)
          .then(response => {
            if (response.result.status === 200) {
              this.getCariEkstre()
            }
          })
    },
    getCariEkstre() {
      const fd = this.postSchema()
      fd.append('methodName', 'cariEkstre')
      axios.post('', fd)
          .then(response => {
            this.bakiye = response.bakiye || 0
            store.commit({
              type: 'SET_CARI_EKSTRE',
              action: 1,
              list: response.cariEkstre,
            })
          })
    },
    postSchema() {
      const fd = new FormData()
      fd.append('crm_token', store.state.userToken || localStorage.getItem('restoranUserToken'))
      fd.append('serviceName', 'cari')
      fd.append('cariKartID', this.id)
      return fd
    },
    refreshStop(cardName) {
      setTimeout(() => {
        this.$refs[cardName || 'first-card'].showLoading = false
      }, 1000)
    },
  },
}
</script>
<style scoped>
.list-group-item p {
  display: inline-block;
  margin: 0;
}

.media-col {
  display: grid;
  place-items: center;
}

.pull-up {
  transition: 300ms;
}

.giris-turu-badge {
  border-radius: 3px;
}

.islem-hr-color {
  opacity: 0.6;
}

.ekstre-list-table td, .ekstre-list-table th {
  padding: 0.22rem 2rem !important;
}
</style>
